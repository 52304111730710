import React, {useContext, useEffect, useState} from "react";
import {Alert, Card, message, notification, Typography} from "antd";
import {isMobile} from "device-detect";
import Content from "@/components/Content";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import setTitle from "@/components/helpers/setTitle";
import {Formik} from "formik";
import api from "../api";
import {Form, FormItem, Input, Radio, SubmitButton} from "formik-antd";
import {CheckOutlined, UserAddOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import useOrgSlug from "@/components/helpers/useOrgSlug";
import {useParams} from "react-router-dom";
import OrgLogo from "@/components/widgets/OrgLogo";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import SignUpForm from "@/components/registration/SignUpForm";
import useCurrentUser from "~/components/helpers/useCurrentUser";

const FullWidthFormItem = ({children, ...props}) => {
    return (
        <FormItem labelCol={{ span: 24 }} {...props}>{children}</FormItem>
    )
}

export default ()=> {
    const {t} = useTranslation();

    setTitle(t('trial-sign-up-title','Trial Sign Up'));

    const [submitted, setSubmitted] = useState()
    let lastSlugCheck = null;
    let lastTitleCheck = null;

    const currentUser = useCurrentUser()

    const parameterize = text => {
        if(!text) return ''
        return text.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'')
    }

    const [token, setToken] = useState()

    useEffect(()=>{
        const turnstile = window.turnstile

        turnstile.ready(function () {
            turnstile.render('#turnstile', {
                sitekey: '0x4AAAAAAAVs-NulzqsYDS-B',
                theme: 'light',
                callback: function(token) {
                    setToken(token)
                },
            });
        });
    },[]);

    return (
        <div style={{paddingTop:'2em'}}>
            <Card title={<><UserAddOutlined/> {t('mg-trial-sign-up','Mediagraph Trial Sign Up')}</>} style={{width: isMobile() ? '100%' : 600, margin:'0 auto'}}>
                {submitted ? (
                    <Alert
                        showIcon
                        style={{marginBottom: '1em'}}
                        type='success'
                        message={<Content code={currentUser ? 'trial-signup-existing-user' : 'trial-signup-next-steps'}/>}
                    />
                ) : (
                    <VerticalSpace size={'large'}>
                        <Alert message={<Content code={'trial-signup-note'}/>}/>

                        <Formik
                            initialValues={{ }}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(true)

                                values.organization_slug = parameterize(values.organization_slug)
                                api.post('/api/trial_signups', {trial_signup: values, token}).then(res => {
                                    actions.setSubmitting(false)
                                    message.success(t('submitted', 'Submitted') + '!')
                                    setSubmitted(true)
                                }).catch(err => {
                                    actions.setSubmitting(false)
                                    message.error(JSON.stringify(err.errors))
                                })
                            }}
                            validationSchema={
                                Yup.object({
                                    name: !currentUser && Yup.string().required(t('required','Required')),
                                    email: !currentUser && Yup.string().email().required(t('required','Required')),
                                    organization_slug: Yup.string()
                                        .required('Required')
                                        .test('checkUniqueSlug', t('slug-already-exists-validation','Organization URL already exists, please choose another.'), value => {
                                            if(!value || value === '') return false;
                                            if(value === lastSlugCheck) return true;

                                            return new Promise(resolve => {
                                                api.post('/api/organizations/check_slug', {slug: value}).then((res)=>{
                                                    if(res.data.ok) {
                                                        // lastSlugCheck = value;
                                                        resolve(true)
                                                    } else {
                                                        resolve(false)
                                                    }
                                                }).catch(() => resolve(false));
                                            })
                                        }),
                                    organization_title: Yup.string()
                                        .required('Required')
                                        .test('checkUniqueTitle', t('title-already-exists-validation','Organization Title already exists, please choose another.'), value => {
                                            if(!value || value === '') return false;
                                            if(value === lastTitleCheck) return true;

                                            return new Promise(resolve => {
                                                api.post('/api/organizations/check_title', {title: value}).then((res)=>{
                                                    if(res.data.ok) {
                                                        // lastTitleCheck = value;
                                                        resolve(true)
                                                    } else {
                                                        resolve(false)
                                                    }
                                                }).catch(() => resolve(false));
                                            })
                                        }),
                                    intended_use: Yup.string().required(t('required','Required')),
                                })
                            }
                        >
                            {({isSubmitting, values, submitForm, isValid, dirty}) => (
                                <Form id={'trial-signup-form'}>
                                    {!currentUser && (
                                        <>
                                            <FullWidthFormItem name="name" required showValidateSuccess label={t('your-name', 'Your Name')}>
                                                <Input
                                                    autoFocus
                                                    required
                                                    type='name'
                                                    autoComplete='name'
                                                    name="name"
                                                    aria-label={'name'}
                                                />
                                            </FullWidthFormItem>

                                            <FullWidthFormItem name="email" required showValidateSuccess label={t('your-email-address', 'Your Email Address')}>
                                                <Input
                                                    required
                                                    type='email'
                                                    name="email"
                                                    autoComplete='email'
                                                    aria-label={'email'}
                                                />
                                            </FullWidthFormItem>
                                        </>
                                    )}

                                    <FullWidthFormItem name="organization_slug" required showValidateSuccess label={t('desired-org-slug', 'Desired Organization URL')}>
                                        <Input
                                            required
                                            type='text'
                                            name="organization_slug"
                                            autoComplete='none'
                                        />
                                    </FullWidthFormItem>

                                    <Typography.Paragraph type="secondary">
                                        {t('your-organization-url','Your Organization will be found at:')}
                                        <br/>
                                        <strong>
                                            {`https://mediagraph.io/${parameterize(values.organization_slug) || '...'}`}
                                        </strong>
                                    </Typography.Paragraph>


                                    <FullWidthFormItem name="organization_title" required showValidateSuccess label={t('desired-org-title', 'Desired Organization Title')}>
                                        <Input
                                            required
                                            type='text'
                                            name="organization_title"
                                            autoComplete='none'
                                        />
                                    </FullWidthFormItem>

                                    <Typography.Paragraph type="secondary">
                                        {t('organization-title-note', 'Most Mediagraph accounts are used for companies, organizations, schools, non-profits, etc. However we also have clients who set up the account for individual or family use.')}
                                    </Typography.Paragraph>

                                    <FullWidthFormItem name="intended_use" required label={t('intended-use-note','Tell us a bit about how you intend to use Mediagraph.')}>
                                        <Input.TextArea
                                            required
                                            name="intended_use"
                                        />
                                    </FullWidthFormItem>

                                    <FullWidthFormItem name="current_dam" label={t('current-dam-label','How do you currently manage your assets?')}>
                                        <Radio.Group name={'current_dam'} required>
                                            <VerticalSpace>
                                                <Radio value={'Hard drive or server'}>{t('hard-drive-or-server', 'Hard drive or server')}</Radio>
                                                <Radio value={'Lightweight DAM'}>{t('lightweight-dam-label', 'Lightweight DAM (Box, Dropbox, Google Drive)')}</Radio>
                                                <Radio value={'DAM System'}>{t('dam-system', 'DAM System')}</Radio>
                                            </VerticalSpace>
                                        </Radio.Group>
                                        <Input.TextArea name="current_dam_details" />
                                    </FullWidthFormItem>

                                    <FullWidthFormItem name="referral" label={t('trial-referral-label','How did you hear about Mediagraph?')}>
                                        <Input.TextArea name="referral" />
                                    </FullWidthFormItem>

                                    <div id={'turnstile'} style={{width: 350, margin:'0 auto 1em'}}/>

                                    <SubmitButton type={'primary'} htmlType={'submit'} block disabled={!isValid} loading={isSubmitting}>
                                        <CheckOutlined/>
                                        {t('submit', 'Submit')}
                                    </SubmitButton>
                                </Form>
                            )}
                        </Formik>
                    </VerticalSpace>
                )}

            </Card>
        </div>
    )
}

const TrialSignupEmailVerified = ()=> {
    const {t} = useTranslation();
    return (
        <>
            <Card title={t('trial-signup','Mediagraph Trial Signup')} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
                <Alert
                    type={'success'}
                    showIcon
                    message={<Content code={'trial-signup-email-verified'}/>}
                />
            </Card>
        </>
    )
}

export {TrialSignupEmailVerified}

const CompleteTrialSignup = ()=> {
    const org = useOrgSlug()
    const {token} = useParams()

    const [loading, setLoading] = useState()
    const [trialSignup, setTrialSignup] = useState()

    useEffect(()=>{
        setLoading(true)

        api(`/api/trial_signups/find?token=${token}`).then(res => {
            setTrialSignup(res.data)
            setLoading(false)
        }).catch(res => {
            notification.error({message: JSON.stringify(res)})
            console.log(res)
        })
    }, [token])

    return (
        <div style={{paddingTop:'2em'}}>
            <Card title={<>Complete {org?.title} Trial Signup</>} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
                {loading && (
                    <>
                        <LoadingOutlined/> Loading Trial...
                    </>
                )}

                {trialSignup && (
                    <SignUpForm trialSignup={trialSignup}/>
                )}
            </Card>
        </div>
    )
}

export {CompleteTrialSignup}
